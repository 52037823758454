<template>
<!-- nav breadcrumb -->
<nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb" id="breadcrumb_container">
<ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page"><router-link :to="{name: 'AccountOverview'}">My Account</router-link></li>
    <li class="breadcrumb-item active" aria-current="page">Cart</li>
</ol>
</nav>

<div id="maincontent_container">
<spinner v-if="loading" />
<div v-if="!loading">
    <!-- error message -->
    <div v-if="message" class="alert alert-success" role="alert">
        <i class="bi bi-check-circle-fill me-3"></i>
        {{message}}
    </div>
    
    <!-- error message -->
    <div v-if="error" class="alert alert-danger" role="alert">
        <i class="bi bi-x-octagon-fill me-3"></i>
        {{error}}
    </div>

    

    <!-- Cart -->
    <p class="text-warning bold">Please review quantity, price and other information below carefully. After clicking "Proceed" button, 
        an E-mail acknowledgment of your Web P.O. will be sent to you.  
        Your Ma Labs Sales Representative will contact you for the details of your Web P.O. soon.
    </p>
    <p class="text-warning bold">A minimum purchase of $15 is required to process your order.</p>
    
    <div class="clearfix">
        <h2 class="float-start">Cart</h2>
        <router-link class="btn btn-primary float-end" :to="{name: 'Quote'}" target="blank">Review Quote</router-link>
    </div>
    <div class="table-responsive">
    <table id="cart_table" class="table">
        <thead>
            <tr>
                <th colspan="2">Product Description</th>
                <th width="10%">Branch</th>
                <th width="8%">Inventory</th>
                <th width="15%">Quantity</th>
                <th>Instant Rebate</th>
                <th>Unit Price</th>
                <th>Subtotal</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index)  in items" :key="index" :id="item.id" name="item">
                <td class="img_col_width">
                    <div class="thumbnail_img">
                        <router-link :to="'/products/details/'+item.itmno">
                        <img :src="item.thumbnail_image" width="75"></router-link>
                    </div>
                </td>
                <td>
                    <!-- <div class="thumbnail_img">
                        <a :href="'/products/details/'+item.itmno">
                        <img :src="item.thumbnail_image" width="75"></a>
                    </div> -->
                    <router-link v-if="item.system" :to="'/config/details/?itmno='+item.itmno">{{item.description}}</router-link>
                    <router-link v-else :to="'/products/details/'+item.itmno">{{item.description}}</router-link>
                    <br/>
                    {{item.itmno}}
                    <div v-if="item.system" class="mt-2">
                        <div class="m-2"><i class="bi bi-wrench-adjustable"></i> <a class="pointer" @click="toggleConfigDetails(item.id)">Configuration Components</a></div>
                        <div class="hide" :id="'config_details_'+item.id">
                            <table class="table table-condensed table-striped">
                                <tbody>
                                <tr v-for="cp in item.system_details.item_list">
                                    <td width="20%" valign="top" class="pe-2">{{cp.itmno}}</td>
                                    <td width="80%">{{cp.desc}} ({{cp.qty}} pcs)  </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
                <td>{{item.branch_full_name}}</td>
                <td>{{item.inventory}}</td>
                <!-- quantity -->
                <td>
                    <input type="text" class="qty float-start" v-model="item.quantity" name="qty" :id="'qty_input_'+item.id" />
                    <button class="btn btn-link btn-sm" @click="updateCartItem(item)">Update</button>
                    <span v-if="update_spinner[item.id]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <button class="btn btn-link btn-sm" @click="removeCartItem(item)">Remove</button>
                    <span v-if="remove_spinner[item.id]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </td>
                <!-- instant rebate  -->
                <td>
                    <div v-if="item.instant_rebate_total == 0.0">N/A</div>
                    <div v-else>
                        <div>{{formatPrice(parseFloat(item.instant_rebate_total).toFixed(2))}}</div>
                        <div class="text-success mt-2">
                            (<span>{{formatPrice(parseFloat(item.instant_rebate).toFixed(2))}} per unit</span> 
                            <span v-if="item.rebstop"><br>valid until {{item.rebstop}}</span>) 
                        </div>
                    </div>
                </td>
                <td align="right">{{formatPrice(parseFloat(item.unit_price).toFixed(2))}}</td>
                <td align="right">{{formatPrice(parseFloat(item.sub_total).toFixed(2))}}</td>
            </tr>
            <tr>
                <td colspan="8" align="right">
                    <div><span class="bold padding">Total Price:</span><span class="padding2">{{formatPrice(parseFloat(total_price).toFixed(2))}}</span></div>
                    <div><span class="bold padding">Total Instant Rebate:</span>
                        <span v-if="total_rebate" class="padding2">{{formatPrice(parseFloat(total_rebate).toFixed(2))}}</span>
                        <span v-else class="padding2">N/A</span>
                    </div>
                    <div><span class="bold padding">Grand Total:</span><span class="padding2">{{formatPrice(parseFloat(grand_total_price).toFixed(2))}}</span></div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <p class="text-warning bold">* Some Brands may not be eligible for international purchase and can only be sold to USA Domestic customers only.
         Please verify with your sales rep for further information.
    </p>

    <div class="row">
        <div class="col">
            <div v-if="total_price" class="float-end">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button v-if="checkout_spinner" class="btn btn-warning btn-lg" id="cart_btn" @click="handleContinueShopping">
                        Continue Shopping
                    </button>
                    <button v-if="checkout_spinner" class="btn btn-primary btn-lg" id="cart_btn" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Proceed to Checkout
                    </button>
                </div>

                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button v-if="!checkout_spinner" class="btn btn-warning btn-lg" id="cart_btn" @click="handleContinueShopping">
                        Continue Shopping
                    </button>
                    <button v-if="!checkout_spinner" class="btn btn-primary btn-lg" id="cart_btn" @click="handleCheckout">
                        Proceed to Checkout
                    </button>
                </div>            
            </div>
            <div v-else class="float-end">
                <button class="btn btn-warning btn-lg" id="cart_btn" @click="handleContinueShopping">
                    Continue Shopping
                </button>
            </div>
        </div>
    </div>
  
    <!-- end of Cart -->

    <p><u>Terms &amp; Conditions</u><br>
		The Terms and Conditions of Sale at <router-link :to="{ name: 'TermsAndConditions'}">www.malabs.com/terms-conditions</router-link> apply to all transactions.
		<br><br>
    </p>
    <p><u>Price and Availability</u><br>
		Please check with your account manager for final pricing and product availability (subject to change without prior notice). 
        Unless otherwise indicated, all price quotes are based on a cash discount offered to all buyers for payment by cash or check as permitted by law.  
        Some Brands may not be eligible for sale outside the U.S. Please review with your account manager. All logistics charges, fees, costs, and taxes are subject to change based on final destination.
		<br><br>
    </p>
    <p><u>Destination Control Statement</u><br>
		Diversion contrary to US export laws of goods or services purchased from Ma Labs is strictly prohibited.
		<br><br>
    </p>
    
</div>
</div>
</template>

<script>
import Spinner from '../../components/Spinner.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getAuthAPI } from '../../utils/axios-api'
import { formatPrice, isInteger } from '../../utils/common'
import $ from 'jquery'

export default {
 name: 'Cart',
 components: { Spinner },
 setup(props, context) {
     
    //loading the page
    const loading = ref(true)
    const message = ref('')
    const error = ref('')
    const remove_spinner = ref([])
    const update_spinner = ref([])
    const checkout_spinner = ref(false)
    
    const router = useRouter()
    const store = useStore()

    //cart items
    const items = ref(null)
    const total_price = ref(0)
    const grand_total_price = ref(0)
    const total_rebate = ref(0)

    //get cart item list
    var url = '/order/get-cart/'
    getAuthAPI.get(url)
    .then(response => {
        loading.value = false
        items.value = response.data
        for (var i = 0; i < items.value.length; i++){
            items.value[i]["branch_full_name"] = branch_full_name(items.value[i]["branch"])
            remove_spinner.value[items.value[i]["id"]] = false
            update_spinner.value[items.value[i]["id"]] = false
        }

        cal_total()

    })
    .catch(err => {
        loading.value = false
        error.value = err.response.data
    })
    

    //function to turn branch code (L, A, G..) into branch name (Los Angeles, Miami, George..)
    function branch_full_name(branch_code) {
        var branch = store.getters.branch
        for (let key in branch) {
            if (branch_code == branch[key]["code"]) {
                return branch[key]["name"]
            }
        }
    }

    function cal_total() {
        total_price.value = 0
        total_rebate.value = 0
        for (var i = 0; i < items.value.length; i++){
            items.value[i]["total_price"] = items.value[i]["unit_price"] * items.value[i]["quantity"]
            items.value[i]["instant_rebate_total"] = items.value[i]["instant_rebate"] * items.value[i]["quantity"]
            items.value[i]["sub_total"] = items.value[i]["total_price"] + items.value[i]["instant_rebate_total"]
            total_price.value = total_price.value + items.value[i]["total_price"]
            total_rebate.value = total_rebate.value + items.value[i]["instant_rebate_total"]
        }
        grand_total_price.value = total_price.value + total_rebate.value
        
    }
    
    function validate_item(item) {
        error.value = ""
        $("#qty_input_"+item.id).removeClass("alert-danger")
        if (isInteger(item.quantity) == false) {
            $("#qty_input_"+item.id).addClass("alert-danger")
            error.value = "Quantity is not valid."
            return false
        } 
        if (item.quantity > item.inventory) {
            $("#qty_input_"+item.id).addClass("alert-danger")
            error.value = "Quanity cannot exceed the inventory balance."
            return false
        }
        return true
    }
    function validate() {
        for (var i = 0; i < items.value.length; i++){
            validate_item(items.value[i])
        }
        return true
    }

    //handle check out function
    const handleCheckout = async () => {
        if (grand_total_price.value < 15) {
            error.value = "A minimum purchase of $15 is required to process your order."
            return 0
        }
        
        checkout_spinner.value = true
        
        if (validate() == false) {
            checkout_spinner.value = false
            return 0
        }

        var payload = {"cart_list": []}
        for (var i = 0; i< items.value.length; i++) {
            //for removed item, do not add it to cart list
            if(items.value[i].quantity > 0) {
                payload["cart_list"].push ({"id": parseInt(items.value[i].id), "quantity": parseInt(items.value[i].quantity)})
            }
        }
        
        try {
            let result = await store.dispatch('checkOut', payload)

            checkout_spinner.value = false
            router.push({ name: 'Shipping' })
        }
        catch(err) {
            checkout_spinner.value = false
            error.value = err
        }
        
    }


    //handle  continue shopping function
    const handleContinueShopping = async()=> {
        checkout_spinner.value = true
        router.push({name:"Products"})
        
    }


    //handle remove
    const removeCartItem = async (item) => {
        error.value = null
        message.value = null
        remove_spinner.value[item.id] = true

        let payload = {"id": item.id}
        
        //add id into removed list
        item.quantity = 0

        try {
            let result = await store.dispatch('removeCartItem', payload)
            
            //update cart quantity page on header
            let cart_qty = await store.dispatch('getCartTotalQty')
            context.emit('updateCartQty', cart_qty)

            //update the total price
            cal_total()

            //remove the item row
            var obj = document.getElementById(item.id);
            obj.remove()
            
            message.value = result
            remove_spinner.value[item.id] = false
            
        }
        catch(err){
            error.value = err
            remove_spinner.value[item.id] = false
        }
        

    }


    //handle qty update
    const updateCartItem = async (item) => {
        error.value = null
        message.value = null
        update_spinner.value[item.id] = true

        if (validate_item(item) == false) {
            update_spinner.value[item.id] = false
            return 0
        }
        let payload = {"id": item.id, "quantity": item.quantity}
  
        try {
            let result = await store.dispatch('updateCartItem', payload)
            
            //update cart quantity page on header
            let cart_qty = await store.dispatch('getCartTotalQty')
            context.emit('updateCartQty', cart_qty)

            //update the sub total price
            cal_total()

            //remove the row if quantity is 0
            if (item.quantity == 0) {
                var obj = document.getElementById(item.id);
                obj.remove()
            }

            message.value = result
            update_spinner.value[item.id] = false
        }
        catch(err){
            error.value = err
            update_spinner.value[item.id] = false
        }
        
        
    }

    function toggleConfigDetails(itmno) {
        $("#config_details_" + itmno).toggle();
    }

    return { error, 
            message,
            loading, 
            remove_spinner,
            update_spinner,
            checkout_spinner,
            items, 
            total_price,
            total_rebate,
            grand_total_price,
            handleCheckout,
            removeCartItem,
            updateCartItem,
            handleContinueShopping,
            formatPrice,
            toggleConfigDetails
            }
 }
}
</script>

<style scoped>
#cate_nav {padding:15px 0 10px ;}
input.qty {width:40px; padding:2px; border:1px solid #ccc; border-radius: 4px;}
.bold {font-weight: bold;}
.padding {display: inline-block; width:250px; padding-right:10px;}
.padding2 {display: inline-block; width:100px; padding-left:10px;}
.thumbnail_img {float:left; padding-right:30px;}
.pointer {color:#000;cursor: pointer;}
.hide {display: none;}
.img_col_width {width:50px; padding-right:0;}
</style>